
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loader.service';

@Component({
	selector: 'loading',
	templateUrl: './loader.component.html',
	styleUrls: ['loader.component.scss']
})

export class LoadingComponent implements AfterViewChecked, OnDestroy {
	public loading = new BehaviorSubject<boolean>(false);
	public loadingIndicator$ = this.loading.asObservable();

	// constructor
	constructor(
		public loader: LoadingService,
		private cdRef: ChangeDetectorRef,
	) {
	}
	ngAfterViewChecked(): void {
		this.loadingIndicator$ = this.loader.loading$;
		this.cdRef.detectChanges();
	}
	// life cycle event
	ngOnDestroy(): void {
		this.loading.complete();
	}
}
