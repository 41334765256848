import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, DestroyRef, inject, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, EventMessage, EventType, InteractionStatus, InteractionType, LogLevel, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { IgxExcelStyleLoadingValuesTemplateDirective, IgxIconService } from 'igniteui-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { Globals } from 'src/globals';
import { AuthenticationService } from './core/services/authentication.service';
import { LocalService } from './core/services/local.service';
import { PolicyService } from './core/services/policy.service';
import { JWTTokenService } from './core/services/jwttoken.service';
import { UsersService } from './core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './pages/dialogs/alert-dialog/alert-dialog.component';
import { HttpEventType } from '@angular/common/http';
import { LoadingService } from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  title = 'SAM';
  isIframe = false;
  loginDisplay: any = null;
  isRedirectPage = false;
  private readonly _destroying$ = new Subject<void>();
  public isNavigating = new BehaviorSubject<boolean>(false);
  public isNavigating$ = this.isNavigating.asObservable();
  // public isNavigating: any = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private authService: MsalService,
    private authenticationService: AuthenticationService,
    public iconService: IgxIconService,
    private dialog: MatDialog,
    private localStorage: LocalService,
    private jwtTokenService: JWTTokenService,
    private policyService: PolicyService,
    private userService: UsersService,
    public loader: LoadingService,
    public globals: Globals,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {
    // this.loading.next(true);
    this.loader.loading.next(true);
    if (window.location.href.startsWith('https://dev-app.automatedtrackers.com')) {
      this.isRedirectPage = true;
    }
    // this.router.events.subscribe((e: any) => {
    //   this.navigationInterceptor(e);
    // })
    this.router.events
      // .pipe(
      //   filter((e) => e instanceof NavigationStart && e.navigationTrigger === 'imperative'),
      //   switchMap(() => this.router.events.pipe(
      //     filter(e => e instanceof NavigationEnd
      //       || e instanceof NavigationCancel
      //       || e instanceof NavigationError
      //     ),
      //     take(1),
      //     filter(e => e instanceof NavigationEnd)
      //   ))
      // )
      .subscribe((val: any) => {
        // if (val instanceof NavigationStart && val.navigationTrigger === 'imperative') {
        this.navigationInterceptor(val);
        // }
      });
    this.LoadAsync();
  }
  LoadAsync = async () => {
    await this.authService.instance.initialize();
    await this.authService.instance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked
    return true;
  };
  async ngOnInit(): Promise<void> {
    if (window.location.href.startsWith('https://dev-app.automatedtrackers.com')) {
      this.isRedirectPage = true;
    }
    // this.loading.next(true);
    // this.loader.hide();
    // this.loader.show();
    this.cdRef.detectChanges();
    let flag: any = false;
    if (!this.authService.instance) {
      flag = this.LoadAsync();
    }
    else {
      flag = true;
    }

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    if (window.location.hash !== '' && window.location.href.startsWith('https://dev-app.automatedtrackers.com')) {
      this.router.events.subscribe((evt: any) => {
        const codeParam: any = this.getParameterByName('state', window.location.hash);
        if (codeParam) {
          const redirectUri = codeParam.split('|')[1];
          if (redirectUri === 'localhost') {
            window.location.href = `http://localhost:4200/redirect${window.location.hash}`;
          }
          else {
            window.location.href = `https://${redirectUri}/redirect${window.location.hash}`;
          }
        }

        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
    // })
    this.authService.handleRedirectObservable().subscribe(async (redirectResponse: any) => {
      if (redirectResponse != null) {
        // Acquire token silent success        
        this.jwtTokenService.setToken(redirectResponse.accessToken);
        let role = await this.localStorage.getData('role');
        if (role == null || role === '') {
          this.authenticationService.getRole();
        }
        let policy = await this.localStorage.getData('policy');
        if (policy == null || policy === '') {
          this.policyService.getByUser().subscribe(res => {
            res = res.map((x: any) => {
              return {
                PolicyArea: x.gat_id,
                AccessTypeId: x.pol_access
              }
            });
            this.localStorage.saveData('policy', JSON.stringify(res));
          }, error => {

          });
        }
        let user = await this.localStorage.getData('localUser');
        if (user == null || user === '') {
          this.userService.getCurrentUser().subscribe(res => {
            this.localStorage.saveData('localUser', JSON.stringify(res));
          }, (error: any) => {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              disableClose: true,
              data: { message: 'You are  not authorized. Please contact to administator.' }
            });
            dialogRef.afterClosed().subscribe(result => {
              this.logout();
            });
          });
        }
      }
      this.authService.instance
        .handleRedirectPromise()
        .then((tokenResponse: any) => {
          let accountObj = null;
          if (tokenResponse !== null) {
            accountObj = tokenResponse.account;
            this.jwtTokenService.setToken(tokenResponse.accessToken);
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
          this.router.navigate(['/']);
        } else {
          this.setLoginDisplay();
        }
      });
  }
  ngAfterViewChecked(): void {
    // this.loadingIndicator$ = this.loader.loading$;
    // this.loader.show();
    if (this.isNavigating.value) {
      this.loader.loading.next(true);
      this.cdRef.detectChanges();
    }
  }
  navigationInterceptor(eventType: any): void {
    if (eventType instanceof NavigationStart) {
      // this.loader.loading.next(true);
      // this.cdRef.detectChanges();      
      if (!eventType.url.includes('system-management')) {
        this.isNavigating.next(true);
      }
    }

    if (eventType instanceof NavigationEnd) {
      if (!eventType.url.includes('/house/')) {
        this.isNavigating.next(false);
        //this.loader.hide(1);
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (eventType instanceof NavigationCancel) {
      if (!eventType.url.includes('/house/')) {
        this.isNavigating.next(false);
        //  this.loader.hide(1);
      }
    }

    if (eventType instanceof NavigationError) {
      if (!eventType.url.includes('/house/')) {
        this.isNavigating.next(false);
        //this.loader.hide(1);
      }
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (!window.location.href.startsWith('https://dev-app.automatedtrackers.com')) {
      setTimeout(() => {
        this.loader.loading.next(false);
        this.cdRef.detectChanges();
      }, 3000);
    }
    if (!this.loginDisplay && window.location.hash === '') {
      this.router.navigate(['/']);
      // this.router.navigate(['/house']);
    }
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    this.globals.loggedInuser$.next(activeAccount);
    this.globals.isTouched$.next('ontouchstart' in document.documentElement);
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.globals.loggedInuser$.next(accounts[0]);
      this.authService.instance.setActiveAccount(accounts[0]);
      // if (!window.location.href.startsWith('https://dev-app.automatedtrackers.com')) {
      //   setTimeout(() => {
      //     this.loading.next(false);
      //     this.cdRef.detectChanges();
      //   }, 1000);
      // }
    }
  }
  async loginRedirect() {
    await this.authenticationService.signIn();
  }
  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      try {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          }, (err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error)
      }
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        }, (error) => {
          console.log(error);
        });
    }
  }
  logout(popup?: boolean) {
    this.authenticationService.signOut(popup);
    this.localStorage.removeData('localUser');
    this.localStorage.removeData('company');
    this.localStorage.removeData('idToken');
    this.localStorage.removeData('role');
    this.localStorage.removeData('policy');
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    // this.loading.complete();
  }
  getParameterByName(name: any, url: any) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  tatakeUntilDestroyed(destroyRef: DestroyRef): any {
    console.log('sample');
    // this.destroyRef.next.unsubscribe();
  }
}


