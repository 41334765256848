<div *ngIf="cell" class="contextmenu" [ngStyle]="getStyle()">
    <ng-container *ngFor="let item of items">
        <ng-container>
            <div class="button-sample">
                <button igxIconButton="flat" class="item text-start w-100" style="border: none;"
                    (click)="copyRowData($event,item.option)" [disabled]="item.isDisabled">
                    <igx-icon class="icon" family="material">{{item.icon}} </igx-icon>{{item.option}}
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>