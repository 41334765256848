import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class HouseService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('house');
    }

    getHouseByPaged(whereCondition: any = '1=1', orderBy: any, pageIndex: any, pageSize: any, action: string): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&whereCondition=${whereCondition}&orderByExpression=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getRecall(whereCondition: any = '1=1', pageIndex: any, pageSize: any, comapny: any, action: string): Observable<any> {
        return this.http.get(`${this.path}?action=recall&whereCondition=${whereCondition}&pageIndex=${pageIndex}&pageSize=${pageSize}&company=${comapny}`);
    }
    getDuplicateProjectJobId(jobid: number, projectid: number): Observable<any> {
        return this.http.get(`${this.path}?action=projectid&jobid=${jobid}&projectid=${projectid}`);
    }
    checkInOutHouse(action: string, user: number, model: any): Observable<any> {
        return this.http.put(`${this.path}/${user}?action=${action}`, model);
    }
    update(pk: number, model: any): Observable<any> {
        return this.http.put(`${this.path}/${pk}`, model);
    }
    create(model: any): Observable<any> {
        return this.http.post(`${this.path}`, model);
    }
    transferHouse(toBuilder: number, model: any): Observable<any> {
        return this.http.put(`${this.path}?toBuilder=${toBuilder}`, model);
    }
    clearSchedule(house: any): Observable<any> {
        return this.http.get(`${this.path}?action=clearschedule&house=${house}`);
    }
    recallHouse(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    delete(model: any[]) {
        let body: any = { body: model }
        return this.http.delete<any>(`${this.path}`, body);
    }
    getOverviewScheduleBySupervisor(subdivision: number, builder: number): Observable<any> {
        return this.http.get(`${this.path}/OverviewScheduleBySupervisor?subdivision=${subdivision}&builder=${builder}`);
    }
    getOverviewScheduleByBuilder(subdivision: number, builder: number): Observable<any> {
        return this.http.get(`${this.path}/OverviewScheduleByBuilder?subdivision=${subdivision}&builder=${builder}`);
    }
    getHouseList(action: string): Observable<any> {
        return this.http.get(`${this.path}?action=${action}`);
    }
    bulkUpdate(model: any, isWarranty: any = false): Observable<any> {
        return this.http.put<any>(`${this.path}/bulk?isWarranty=${isWarranty}`, model);
    }
    payrollComplete(model: any): Observable<any> {
        return this.http.put(`${this.path}/UpdateHousePayroll`, model);
    }
    getBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=subdivision&subdivision=${subdivision}`);
    }
}