import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    public  loading = new BehaviorSubject<boolean>(true);
    public readonly loading$ = this.loading.asObservable();

    public startCount = new BehaviorSubject<number>(0);
    public readonly startCount$ = this.startCount.asObservable();
    timeout = 2000;
    constructor() { }

    get(): any {
        return this.loading.value;
    }
    show(): void {
        this.startCount.next(this.startCount.value + 1);
        if (!this.loading.value) {
            this.loading.next(true);
        }
    }

    hide(stopCount: any = 0): void {
        if (stopCount !== 0 && this.startCount.value > 0) {
            this.startCount.next(this.startCount.value - 1);
        }
        else {
            this.startCount.next(0);
        }
        if (this.startCount.value === 0) {
            this.loading.next(false);
        }
    }

    toggle(): void {
        this.loading.next(!this.loading.value);
    }
}