import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class BackChargeService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('back-charge');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    getByPORequest(pk: any, isWarranty: any = false): Observable<any> {
        return this.http.get(`${this.path}?action=porequest&id=${pk}&iswarranty=${isWarranty}`);
    }
    bulkInsert(model: any[], isWarranty: any = false): Observable<any> {
        return this.http.put<any>(`${this.path}?iswarranty=${isWarranty}`, model);
    }
    bulkUpdate(model: any) {
        return this.http.put<any>(`${this.path}/UpdateTaskPO`, model);
    }
    delete(pks: any, isBackCharge:any = false, isWarranty: any = false): Observable<any> {
        let body: any = { body: pks }
        return this.http.delete<any>(`${this.path}?isBackCharge=${isBackCharge}&isWarranty=${isWarranty}`, body);
    }
}