import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment, CanActivateChild, CanActivateFn, CanLoadFn, CanMatchFn } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LocalService } from '../services/local.service';
import { AuthenticationScheme } from '@azure/msal-browser';
import { AuthenticationService } from '../services/authentication.service';
import { ToasterService } from 'src/app/toaster/toaster.service';
import { MessageType } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: MsalService,
    private authenticationService: AuthenticationService,
    private toast: ToasterService,
    private localStorage: LocalService
  ) { }
  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  async canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<any> {
    if (route?.data?.['path']) {
      if (await this.authenticationService.hasPermission(route?.data?.['policyArea'].toLowerCase())) {
        return true;
      }
      // this.toast.show(MessageType.Warning, '', 'You are not authorize to access this page');
      this.router.navigate(['unauthorized']);
      return false;
    }
  }
}
