<igx-card-header class="dialog-header">
    {{isModify ? 'Update': 'Add'}} PO Request
</igx-card-header>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>

<div id="add-vendor" (window:resize)="onResize($event)" class="p-2" style="height: calc(100% - 60px);overflow: auto;">
    <div style="height: calc(100%)">
        <div style="width: 100%;display: flex;" *ngIf="isPermissionModify">
            <div style="padding: 10px;width: 100%;">
                <div class="button-sample" style="padding: 0 5px; float: right">
                    <a (click)="save()">
                        <span class="pcoded-micon">
                            <img class="png-icon" src='assets/icons/floppy-disk-solid.svg'
                                style="height: 50px;width: 50px;" matTooltip="Save">
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <form class="p-2 form-wrapper" #parentForm="ngForm">
            <igx-card>
                <igx-card-header class="igx-card-header">
                    EPO Form: Parent Form
                </igx-card-header>
                <igx-card-content class="gradiant">
                    <fieldset
                        [disabled]="!(checkoutUser
                || isAdministrator || isAssistanceBuilder) || permission.poRequest < rolesEnum.ModifyOnly || poRequest.por_iscompleted">
                        <table class="mt-2 column" style="width: 100%;height: 100%;">
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="isModify">
                                    <div *ngIf="isHousePORequest">
                                        <label igxLabel class="igx-label">Completed?:</label>
                                        <igx-checkbox class="checkbox-center" [(ngModel)]="poRequest.por_iscompleted"
                                            #isCompleted="ngModel" name="isCompleted"
                                            [disabled]="!poRequest.por_approve || poRequest.por_po === '' || house.hou_payrollcompleted || poRequest.por_iscompleted">
                                        </igx-checkbox>
                                    </div>
                                    <div *ngIf="!isHousePORequest">
                                        <label igxLabel class="igx-label">Approved:</label>
                                        <igx-checkbox class="checkbox-center" [(ngModel)]="poRequest.por_approve"
                                            #isApprove="ngModel" (change)="poRequest.por_isreject = false"
                                            [disabled]="!isHousePORequest && poRequest.por_iscompleted"
                                            name="isApprove">
                                        </igx-checkbox>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Urgent:</label>
                                    <igx-checkbox class="checkbox-center" #urgent="ngModel" name="urgent"
                                        [(ngModel)]="poRequest.por_urgentpo" [disabled]="poRequest.por_iscompleted">
                                    </igx-checkbox>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="isModify">
                                    <div *ngIf="isHousePORequest">
                                        <label igxLabel class="igx-label">Resubmit?:</label>
                                        <igx-checkbox class="checkbox-center" [(ngModel)]="poRequest.por_isreject"
                                            #isRejected="ngModel" name="isRejected"
                                            [disabled]="poRequest.por_iscompleted || poRequest.por_po === '' || !poRequest.por_isreject">
                                        </igx-checkbox>
                                    </div>
                                    <div *ngIf="!isHousePORequest">
                                        <label igxLabel class="igx-label">Rejected:</label>
                                        <igx-checkbox class="checkbox-center" [(ngModel)]="poRequest.por_isreject"
                                            #isRejected="ngModel" name="isRejected"
                                            (change)="poRequest.por_approve = false" [disabled]="poRequest.por_approve">
                                        </igx-checkbox>
                                    </div>
                                </td>
                            </tr>
                            <tr class="row" *ngIf="!isHousePORequest">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Subdivision:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.sub_name"
                                            #subName="ngModel" name="subName" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Project #:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.projectId"
                                            #projectId="ngModel" name="projectId" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">{{ isHousePORequest === true ? 'Request' :
                                        'Purchase' }} Date:</label>
                                    <div class="date-picker-wrapper">
                                        <igx-date-picker [(ngModel)]="poRequest.por_date" #requestDate="ngModel"
                                            name="requestDate" [disabled]="true">
                                        </igx-date-picker>
                                    </div>
                                </td>
                            </tr>
                            <tr class="row" *ngIf="!isHousePORequest">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">House:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.hou_address"
                                            #houAddress="ngModel" name="houAddress" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Builder:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.usr_name"
                                            #usrName="ngModel" name="usrName" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Job #:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.hou_jobid"
                                            #houJobId="ngModel" name="houJobId" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                            </tr>
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="isHousePORequest">
                                    <label igxLabel class="igx-label">Vendor Type:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_vetfk" [data]="vendorTypes"
                                        (selectionChanging)="changeVendorType($event)" #vendorType="ngModel"
                                        name="vendorType" [displayKey]="'vet_name'" [valueKey]="'vet_pk'"
                                        placeholder="Select Vendor Type" required
                                        [disabled]="poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                    <div *ngIf="vendorType.invalid && (vendorType.dirty || vendorType.touched)"
                                        class="alert">
                                        <div *ngIf="vendorType.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Vendor/Trades *:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_confk" [data]="poRequestVendor"
                                        (selectionChanging)="changeVendor($event)" #contact="ngModel" name="contact"
                                        [displayKey]="'con_name'" [valueKey]="'con_pk'" required
                                        [disabled]="!isHousePORequest || poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                    <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="alert">
                                        <div *ngIf="contact.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Variance #:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_varfk" [data]="variances"
                                        (selectionChanging)="changeVariance($event)" #variance="ngModel" name="variance"
                                        [displayKey]="'var_name'" [valueKey]="'var_pk'" required
                                        [disabled]="!isHousePORequest || poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                    <div *ngIf="variance.invalid && (variance.dirty || variance.touched)" class="alert">
                                        <div *ngIf="variance.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">{{hasVariance('W') === true ? 'Warranty #:' :
                                        'Change Order #:'}}</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.por_eco" #eco="ngModel"
                                            [igxTextSelection]="true" name="eco" type="text"
                                            [disabled]="!isHousePORequest || poRequest.por_iscompleted" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Vendor Id:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_confk" [data]="poRequestVendor"
                                        #vendorId="ngModel" name="vendorId" [displayKey]="'con_id'"
                                        [valueKey]="'con_pk'"
                                        [disabled]="!isHousePORequest || poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                </td>
                            </tr>
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Cost Code Description:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_ccdfk" [data]="poRequestCostcodes"
                                        #ccdDesc="ngModel" name="ccdDesc" [displayKey]="'ccd_name'"
                                        [valueKey]="'ccd_pk'" required
                                        [disabled]="!isHousePORequest || poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                    <div *ngIf="ccdDesc.invalid && (ccdDesc.dirty || ccdDesc.touched)" class="alert">
                                        <div *ngIf="ccdDesc.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Cost Code:</label>
                                    <igx-simple-combo [(ngModel)]="poRequest.por_ccdfk" [data]="poRequestCostcodes"
                                        #ccdId="ngModel" name="ccdId" [displayKey]="'ccd_id'" [valueKey]="'ccd_pk'"
                                        [disabled]="!isHousePORequest || poRequest.por_iscompleted">
                                    </igx-simple-combo>
                                    <div *ngIf="ccdId.invalid && (ccdId.dirty || ccdId.touched)" class="alert">
                                        <div *ngIf="ccdId.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="!isHousePORequest">
                                    <label igxLabel class="igx-label">P.O Submitted:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.poRquestSubmitted"
                                            #poSubmitted="ngModel" name="poSubmitted" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                            </tr>
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">P.O #:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.por_po" #po="ngModel"
                                            [igxTextSelection]="true" name="po" type="text"
                                            (keyup)="onKeyUpDuplicateCheck($event)"
                                            [disabled]="poRequest.por_iscompleted" />
                                    </igx-input-group>
                                    <div *ngIf="poRequest.por_approve === true && poRequest.por_iscompleted === false && (poRequest.por_po ===null || poRequest.por_po==='' )"
                                        class="alert">
                                        <div>Required. </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">P.O Admin:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.con_name"
                                            #poAdmin="ngModel" [igxTextSelection]="true" name="poAdmin" type="text"
                                            [readonly]="true" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="!isHousePORequest">
                                    <label igxLabel class="igx-label">Approved By:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.approvedBy"
                                            #approveBy="ngModel" name="approveBy" type="text" [disabled]="true" />
                                    </igx-input-group>
                                </td>
                            </tr>
                            <tr class="row">
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Amount:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.por_amount"
                                            #amount="ngModel" [igxTextSelection]="true" name="amount" type="number"
                                            required [disabled]="!isHousePORequest || poRequest.por_iscompleted" />
                                    </igx-input-group>
                                    <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="alert">
                                        <div *ngIf="amount.errors?.['required']">Required.
                                        </div>
                                    </div>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label igxLabel class="igx-label">Vendor Invoice #:</label>
                                    <igx-input-group>
                                        <input igxInput class="igx-input" [(ngModel)]="poRequest.por_invoice"
                                            #vendorInvoice="ngModel" [igxTextSelection]="true" name="vendorInvoice"
                                            type="text" [disabled]="!isHousePORequest || poRequest.por_iscompleted" />
                                    </igx-input-group>
                                </td>
                                <td class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="!isHousePORequest">
                                    <label igxLabel class="igx-label">Approved Date:</label>
                                    <igx-date-picker [(ngModel)]="poRequest.por_approveddate" #approveDate="ngModel"
                                        name="approveDate" [disabled]="true">
                                    </igx-date-picker>
                                </td>
                            </tr>
                            <tr class="row table-textbox">
                                <div class="column" style="width: 33%;">
                                    <table class="w-100">
                                        <tr>
                                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                *ngIf="poRequest.por_isreject">
                                                <label igxLabel class="igx-label">Reject Reason:</label>
                                                <div style="position: relative;">
                                                    <igx-input-group>
                                                        <textarea igxInput class="igx-input"
                                                            [(ngModel)]="poRequest.por_rejectreason"
                                                            #rejectedreason="ngModel" name="rejectedreason" rows="3"
                                                            [disabled]="!poRequest.por_isreject || poRequest.por_iscompleted"> </textarea>
                                                    </igx-input-group>
                                                    <div id="speech" class="speech-btn"
                                                        *ngIf="!voiceRecognitionService.notSupported"
                                                        (mousedown)="speechStart($event,'rejectreason')"
                                                        (mouseup)="speechStop($event,'rejectreason')">
                                                        <div class="pulse-ring">
                                                        </div>
                                                        <i class="fa fa-microphone speech-icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div *ngIf=" poRequest.por_isreject===true &&
                                                        poRequest.por_rejectreason===''" class=" alert">
                                                    <div>Required.</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label igxLabel class="igx-label">Purchasing Coordinator
                                                    Comment:</label>
                                                <div style="position: relative;">
                                                    <igx-input-group>
                                                        <textarea igxInput class="igx-input"
                                                            [(ngModel)]="poRequest.por_purchase_comments"
                                                            #purComment="ngModel" name="purComment" rows="3"
                                                            [disabled]="poRequest.por_iscompleted && permission.poRequestPurchaseComment < rolesEnum.ModifyOnly"></textarea>
                                                    </igx-input-group>
                                                    <div id="speech" class="speech-btn"
                                                        *ngIf="!voiceRecognitionService.notSupported"
                                                        (mousedown)="speechStart($event,'purchasecomment')"
                                                        (mouseup)="speechStop($event,'purchasecomment')">
                                                        <div class="pulse-ring">
                                                        </div>
                                                        <i class="fa fa-microphone speech-icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-sm-12 col-md-5 col-lg-5 col-xl-4">
                                                <label igxLabel class="igx-label">Comment:</label>
                                                <div style="position: relative;">
                                                    <igx-input-group>
                                                        <textarea igxInput class="igx-input"
                                                            [(ngModel)]="poRequest.por_comments" #comment="ngModel"
                                                            name="comment" rows="3" required
                                                            [disabled]="poRequest.por_iscompleted">
                                                    </textarea>
                                                    </igx-input-group>
                                                    <div id="speech" class="speech-btn"
                                                        *ngIf="!voiceRecognitionService.notSupported"
                                                        (mousedown)="speechStart($event,'comment')"
                                                        (mouseup)="speechStop($event,'comment')">
                                                        <div class="pulse-ring">
                                                        </div>
                                                        <i class="fa fa-microphone speech-icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div *ngIf="comment.invalid && (comment.dirty || comment.touched)"
                                                    class="alert">
                                                    <div *ngIf="comment.errors?.['required']">Required.</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="column" style="width: 65%;margin-left: 1%;">
                                    <table class="w-100">
                                        <tr>
                                            <td class="col-sm-12 col-md-7 col-lg-7 col-xl-8">
                                                <igx-card *ngIf="poRequest.por_pk > 0">
                                                    <igx-card-header class="igx-card-header">
                                                        PORequest History
                                                    </igx-card-header>
                                                    <igx-card-content class="gradiant">
                                                        <div class="mat-elevation-z8">
                                                            <div class="grid__wrapper">
                                                                <div class="grid__wrapper__inner">
                                                                    <igx-grid #gridHistory
                                                                        [igxPreventDocumentScroll]="false"
                                                                        [data]="history" height="100%" width="100%"
                                                                        [primaryKey]="'poh_pk'" [autoGenerate]="false"
                                                                        [rowSelection]="'none'" [cellSelection]="'none'"
                                                                        [rowEditable]="false" hiddenColumnsText="Hidden"
                                                                        [batchEditing]="false" [hideRowSelectors]="true"
                                                                        [emptyGridTemplate]="myTemplate">

                                                                        <ng-template #myTemplate>
                                                                            <div
                                                                                style="margin: 5%; text-align: center;">
                                                                                <label style="color: black;">No data
                                                                                    found</label>
                                                                            </div>
                                                                        </ng-template>

                                                                        <igx-column field="poh_rejectdate"
                                                                            header="Reject Date" [dataType]="'date'"
                                                                            [sortable]="false" [resizable]="true"
                                                                            [editable]="false">
                                                                            <ng-template igxCell let-cell="cell"
                                                                                let-val>
                                                                                <span>{{ val | date: 'MM/dd/yyyy'
                                                                                    }}</span>
                                                                            </ng-template>
                                                                        </igx-column>
                                                                        <igx-column field="poh_rejectreason"
                                                                            header="Reject Reason" [dataType]="'string'"
                                                                            [sortable]="false" [resizable]="true"
                                                                            [editable]="false">
                                                                            <ng-template igxCell let-cell="cell">
                                                                                <span>{{cell.value}}</span>
                                                                            </ng-template>
                                                                        </igx-column>
                                                                        <igx-column field="poh_rejectby"
                                                                            header="Reject By" [dataType]="'string'"
                                                                            [sortable]="false" [resizable]="true"
                                                                            [editable]="false">
                                                                            <ng-template igxCell let-cell="cell">
                                                                                <span>{{cell.value}}</span>
                                                                            </ng-template>
                                                                        </igx-column>
                                                                    </igx-grid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </igx-card-content>
                                                </igx-card>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </tr>
                        </table>
                    </fieldset>
                </igx-card-content>
            </igx-card>
            <igx-card *ngIf="this.poRequest.por_pk > 0 && isHousePORequest">
                <igx-card-header class="igx-card-header">
                    Upload File
                </igx-card-header>
                <igx-card-content class="gradiant">
                    <table class="mt-2" style="width: 100%;">
                        <tr class="row">
                            <td class="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                <label igxLabel class="igx-label">Document Path</label>
                            </td>
                            <td class="col-sm-7 col-md-7 col-lg-5 col-xl-4">
                                <igx-input-group class="upload-group"
                                    style="display: inline-block;margin-right: 10px;width: calc(100% - 100px);">
                                    <input igxInput type="file" [ngModel] #porUpload name="porUpload" id="porUpload"
                                        (change)="onFileSelected($event)" class="igx-input upload-file" accept="image/*"
                                        style="--input-size: var(--ig-size, var(--ig-size-small));--component-size: var(--ig-size, var(--ig-size-small));" />
                                </igx-input-group>
                            </td>
                            <td class="col-sm-5 col-md-5 col-lg-5 col-xl-4 manual-col-width-5">
                                <button igxButton="contained" igxRipple class="col-auto igx-primary-btn"
                                    (click)="porUpload.click()">Browse
                                </button>&nbsp;&nbsp;&nbsp;
                                <button igxButton="contained" igxRipple class="igx-primary-btn"
                                    (click)="uploadFile($event)">Upload
                                    File</button>
                            </td>
                        </tr>
                    </table>
                    <div class="mat-elevation-z8" style="margin-top:20px;">
                        <div class="grid__wrapper">
                            <div class="grid__wrapper__inner">
                                <igx-grid #gridContractDocumentSharing [igxPreventDocumentScroll]="false"
                                    [data]="poDocuemtData" height="100%" width="100%" [primaryKey]="'id'"
                                    [autoGenerate]="false" [rowSelection]="'none'" [cellSelection]="'none'"
                                    [rowEditable]="false" hiddenColumnsText="Hidden" [batchEditing]="false"
                                    [hideRowSelectors]="true" [emptyGridTemplate]="myTemplate">

                                    <ng-template #myTemplate>
                                        <div style="margin: 5%; text-align: center;">
                                            <label style="color: black;">No data found</label>
                                        </div>
                                    </ng-template>

                                    <igx-column field="pot_originalname" header="File Name" [sortable]="true"
                                        [resizable]="true" [dataType]="'string'">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="pot_datecreated" header="Date Uploaded" [filterable]="true"
                                        [sortable]="true" [dataType]="'date'" [pipeArgs]="pipeArgs" [resizable]="true">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{ cell.value | date: 'MM/dd/yyyy' }}</span>
                                        </ng-template>
                                    </igx-column>
                                </igx-grid>
                            </div>
                        </div>
                    </div>
                </igx-card-content>
            </igx-card>
            <igx-card [ngClass]="hasVariance('B') === true ? 'hasChildRow' : 'noChildRow'">
                <igx-card-header class="igx-card-header">
                    Child Entry
                </igx-card-header>
                <igx-card-content class="gradiant">
                    <div class="mat-elevation-z8">
                        <div class="grid__wrapper">
                            <div class="grid__wrapper__inner">
                                <igx-grid #gridChildEntry [igxPreventDocumentScroll]="false" [data]="gridBackChargeData"
                                    height="100%" width="100%" [primaryKey]="'bkc_pk'" [autoGenerate]="false"
                                    [rowSelection]="'single'" [cellSelection]="'none'" [rowEditable]="false"
                                    hiddenColumnsText="Hidden" [batchEditing]="true" (selected)="onCellSelected($event)"
                                    (rowSelectionChanging)="handleRowSelection($event)"
                                    (contextMenu)="contextMenuPORequestForm($event)" [hideRowSelectors]="true"
                                    [emptyGridTemplate]="myTemplate">

                                    <ng-template #myTemplate>
                                        <div style="margin: 5%; text-align: center;">
                                            <label style="color: black;">No data found</label>
                                        </div>
                                    </ng-template>

                                    <igx-column field="bkc_isreject" header="Resubmit?" [sortable]="true"
                                        [resizable]="true" [dataType]="'boolean'" [cellTemplate]="chkIsReject">
                                        <ng-template #chkIsReject let-cell="cell">
                                            <igx-checkbox [(ngModel)]="cell.value" [ngModelOptions]="{standalone: true}"
                                                [disabled]="!cell.row.data.bkc_approve || cell.row.data.bkc_po === '' || !(checkoutUser
                                                || isAdministrator || isAssistanceBuilder) || permission.poRequest < rolesEnum.ModifyOnly || house.hou_payrollcompleted">
                                            </igx-checkbox>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_vetfk" header="Vendor Type" [filterable]="true"
                                        [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{vendorTypes | SelectVendorTypeById : cell.value}}</span>
                                        </ng-template>
                                        <ng-template igxCellEditor let-cell="cell" let-value>
                                            <igx-simple-combo [(ngModel)]="cell.value"
                                                [ngModelOptions]="{standalone: true}" [data]="vendorTypes"
                                                [displayKey]="'vet_name'" [valueKey]="'vet_pk'">
                                            </igx-simple-combo>
                                        </ng-template>
                                        <ng-template igxHeader let-cell>
                                            <div><span>Vendor Type</span></div>
                                            <igx-simple-combo (selectionChanging)="changeHeaderChildgrid($event)"
                                                [data]="vendorTypes" [displayKey]="'vet_name'" [valueKey]="'vet_pk'"
                                                [disabled]="poRequest.por_iscompleted">
                                            </igx-simple-combo>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_confk" header="Vendor" [filterable]="true" [sortable]="true"
                                        [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{getVendorName(cell.value)}}</span>
                                        </ng-template>
                                        <ng-template igxCellEditor let-cell="cell" let-value>
                                            <igx-simple-combo [(ngModel)]="cell.value"
                                                [ngModelOptions]="{standalone: true}" [data]="bkcVendorList"
                                                [displayKey]="'con_name'" [valueKey]="'con_pk'">
                                            </igx-simple-combo>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_ccdfk" header="Cost Code" [filterable]="true"
                                        [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{poRequestCostcodeList | SelectCostCodeIdByIdByVendor :
                                                cell.value : cell.row.data.bkc_confk : poRequest.por_varfk}}</span>
                                        </ng-template>
                                        <ng-template igxCellEditor let-cell="cell" let-value>
                                            <igx-simple-combo [(ngModel)]="cell.value"
                                                [ngModelOptions]="{standalone: true}" [data]="bkcCostCodeList"
                                                [displayKey]="'ccd_id'" [valueKey]="'ccd_pk'">
                                            </igx-simple-combo>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_ccdfk" header="Cost Code Description" [filterable]="true"
                                        [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{poRequestCostcodeList | SelectCostCodeByIdByVendor :
                                                cell.value : cell.row.data.bkc_confk : poRequest.por_varfk}}</span>
                                        </ng-template>
                                        <ng-template igxCellEditor let-cell="cell" let-value>
                                            <igx-simple-combo [(ngModel)]="cell.value"
                                                [ngModelOptions]="{standalone: true}" [data]="bkcCostCodeList"
                                                [displayKey]="'ccd_name'" [valueKey]="'con_pk'">
                                            </igx-simple-combo>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_po" header="P.O. #" [dataType]="'string'" [filterable]="true"
                                        [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_amount" header="Amount" [dataType]="'number'"
                                        [filterable]="true" [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                        <ng-template igxCellEditor let-cell="cell">
                                            <igx-input-group>
                                                <input igxInput type="number" [(ngModel)]="cell.editValue"
                                                    (keydown)="onbkcAmountKeydown($event, cell)" [readonly]="permission.poRequest < rolesEnum.ModifyOnly || !(checkoutUser
                                                || isAdministrator || isAssistanceBuilder)"
                                                    [ngModelOptions]="{standalone: true}" />
                                            </igx-input-group>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_invoice" header="Invoice" [dataType]="'string'"
                                        [filterable]="true" [sortable]="true" [resizable]="true" [editable]="false">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_comments" header="Commnets" [dataType]="'string'"
                                        [filterable]="true" [sortable]="true" [resizable]="true"
                                        [editable]="(checkoutUser
                                    || isAdministrator || isAssistanceBuilder) || permission.poRequest >= rolesEnum.ModifyOnly">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="bkc_purchase_comments" header="Purchasing Coordinatory"
                                        [dataType]="'string'" [filterable]="true" [sortable]="true" [resizable]="true"
                                        [editable]="false">
                                        <ng-template igxCell let-cell="cell">
                                            <span>{{cell.value}}</span>
                                        </ng-template>
                                    </igx-column>
                                </igx-grid>
                                <div *ngIf="contextmenu_POReuestForm">
                                    <app-porequest-context-menu [x]="contextmenuX" [y]="contextmenuY"
                                        [cell]="clickedCell" (cellValueCopy)="copyPORequestForm($event)"
                                        (menuItemSelected)="handleMenuSelection()"
                                        [items]="lstContextOption_PORequestForm">
                                    </app-porequest-context-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </igx-card-content>
            </igx-card>
        </form>
    </div>
</div>

<!-- PO Request context menu delete dialog  -->
<igx-dialog #POdialog message="Are you sure you wish to delete PORequest?" [closeOnEscape]="false"
    [closeOnOutsideSelect]="false">
    <igx-dialog-title>
        <div class="dialog-container">
            <div class="dialog-title">SAM</div>
        </div>
    </igx-dialog-title>
    <div igxDialogActions class="dialog-container dialog-actions">
        <button igxButton="flat" igxRipple (click)="deleteBackcharges($event,true)">Yes</button>
        <button igxButton="flat" igxRipple (click)="deleteBackcharges($event,false)">No</button>
    </div>
</igx-dialog>