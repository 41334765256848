import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AdminComponent } from 'src/app/theme/layout/admin/admin.component';

interface ICopyData {
  data: any;
}

@Component({
  selector: 'app-porequest-context-menu',
  templateUrl: './porequest-context-menu.component.html',
  styleUrls: ['./porequest-context-menu.component.scss']
})
export class HousePorequestContextMenuComponent {
  @Input() public x = 0;
  @Input() public y = 0;
  @Input() public cell: any;
  @Input() public selectedCells: any;
  @Output() public cellValueCopy = new EventEmitter<ICopyData>();
  public selectedData: ICopyData[] = [];
  @Output() clickedOutside = new EventEmitter();
  @Output() menuItemSelected = new EventEmitter();
  @Input() public items: any = [];

  constructor(private eRef: ElementRef) {
  }
  ngOnInIt() {

  }
  getStyle() {
    let xAxis: any = 0;
    // if (this.adminComponent.drawer.isOpen) {
    //   xAxis = this.x - 210;
    // }
    // else {
    if (window.screen.width < 992) {
      xAxis = this.x + 68;
    }
    else {
      xAxis = this.x;
    }
    // }
    return {
      left: xAxis + 'px',
      top: this.y + 'px'
    };
  }
  public copyRowData(event: any, optionValue: string) {
    const selectedData = this.cell.row.data;
    Object.defineProperty(selectedData, "selectedOption", { value: optionValue, configurable: true })
    this.copyData(JSON.stringify(this.cell.row.data));
    this.cellValueCopy.emit({ data: selectedData });
  }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.menuItemSelected.emit();
    }
  }

  outputSelectedMenuItem(menuitem: string) {
    this.menuItemSelected.emit(menuitem);
  }

  private copyData(data: any) {
    const tempElement = document.createElement('input');
    document.body.appendChild(tempElement);
    tempElement.setAttribute('id', 'temp_id');
    (document.getElementById('temp_id') as HTMLInputElement).value = data;
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
  }
}
