import { NumberSymbol } from "@angular/common"

export enum AccessLevel {
    NoAccess = 0,
    ViewOnly = 1,
    ModifyOnly = 2,
    AddModifyOnly = 3,
    FullControl = 4
};

export enum Month {
    Jan = 0,
    Feb = 1,
    Mar = 2,
    Apr = 3,
    May = 4,
    Jun = 5,
    Jul = 6,
    Aug = 7,
    Sep = 8,
    Oct = 9,
    Nov = 10,
    Dec = 11
};

export enum MessageType {
    Warning = 'warning',
    Success = 'success',
    Error = 'error'
};

export interface IHousePermission {
    house: number,
    generalInfo: number,
    documentsUpload: number,
    userFields: number,
    creditDays: number,
    generalNotes: number,
    generalWarrantyInformation: number,
    houseOnHold: number,
    houseFinalCloseDate: number,
    schedule: number,
    scheduleDragDrop: number,
    fax: number,
    faxSendDate: number,
    faxVendorTrades: number,
    pos: number,
    activityList: number
    stage10Activity: number,
    houseActivityDelete: number,
    milestoneActivityIncomplete: number,
    note: number,
    punchList: number,
    productionQarter: number,
    payroll: number,
    payrollPending: number,
    payrollSubmitted: number,
    payrollPaid: number,
    autoPayrollPending: number,
    autoPayrollSubmitted: number,
    autoPayrollPaid: number,
    autoPayrollPOSubmitted: Number,
    comments: number,
    poiActivity: number,
    poRequest: number,
    poRequestPurchaseComment: number,
    hoDetails: number,
    hoWarrantyLetter: number,
    hoGallery: number,
    hoWarrantyDocument: number,
    hoClosingDocument: number,
    hoComment: number,
    hoWarrantyNotes: number,
    warrantyLetterPunchList: number,
    sales: number,
    formHouse: number
}