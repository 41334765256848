import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class CompanyService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('company');
    }

    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    get(id: any): Observable<any> {
        return this.http.get(`${this.path}/${id}`);
    }
    getBySubdivision(action: string, subdivision: number): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&subdivision=${subdivision}`);
    }
    getApproveLevelByCompany(companyId: number): Observable<any> {
        return this.http.get(`${this.path}/getApproveLevelByCompany/` + companyId);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    bulkInsert(model: any[]): Observable<any> {
        return this.http.post<any>(`${this.path}?action=bulk`, model);
    }
    bulkUpdate(id: number, model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/${id}?action=bulk`, model);
    }
    transferCompany(action: string, company: number, house: number, model: any): Observable<any> {
        return this.http.put(`${this.path}?action=${action}&company=${company}&house=${house}`, model);
    }
}