import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputBoxTrim]'
})
export class InputBoxTrimDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('blur')
  onBlur() {
    const value = this.el.nativeElement.value;
    const valueTrim = value.trim();
    if (value !== valueTrim) {
      this.el.nativeElement.value = this.el.nativeElement.value.toString().trim();
      this.ngControl.control!.setValue(this.el.nativeElement.value.trim());
    }
  }
}

@NgModule({
  declarations: [InputBoxTrimDirective],
  exports: [InputBoxTrimDirective]
})

export class InputBoxTrimDirectiveModule { }