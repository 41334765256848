// Angular Import
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [

  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'house',
        loadChildren: () => import('./pages/house/house.module').then((m) => m.HouseModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'system-management',
        loadChildren: () => import('./pages/system-management/system-management.module').then((m) => m.SystemManagementModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'vendor-trades',
        loadChildren: () => import('./pages/contact-list/contact-list.module').then((m) => m.ContactListModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'warranty-letters',
        loadChildren: () => import('./pages/warranty-letters/warranty-letters.module').then((m) => m.WarrantyLettersModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'payroll',
        loadChildren: () => import('./pages/payroll/payroll.module').then((m) => m.PayrollModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'all-list',
        loadChildren: () => import('./pages/all-list/all-list.module').then((m) => m.AllListModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'tasks',
        loadChildren: () => import('./pages/tasks/tasks.module').then((m) => m.TasksModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'gantt-chart',
        loadChildren: () => import('./pages/gantt-chart/gantt-chart.module').then((m) => m.GanttChartModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'overview-schedule',
        loadChildren: () => import('./pages/overview-schedule/overview-schedule.module').then((m) => m.OverviewScheduleModule),
        canLoad: [AuthGuard],
        data: { policyArea: '150', path: 'overviewschedule' }
      },
      {
        path: 'unauthorized',
        loadChildren: () => import('./unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
      }
    ]
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'redirect',
        loadComponent: () => import('./redirect/redirect.component')
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
